<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-toolbar flat dark color="secondary">
            <v-toolbar-title>{{
              $t("changepwd") | capitalize
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <input
                id="emailfield"
                type="text"
                hidden
                :value="user.email"
                autocomplete="username"
              />

              <v-text-field
                v-model="oldPassword"
                :label="$t('oldpwd') | capitalize"
                :error-messages="oldPasswordErrors"
                :rules="[rules.required, rules.lowercase, rules.uppercase,
                rules.special, rules.number,  rules.minSize]"
                counter
                :type="showPwd ? 'text' : 'password'"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                @change="oldPasswordErrors=[]"
                @click:append="showPwd = !showPwd"
                autocomplete="current-password"
                required
              ></v-text-field>

              <v-text-field
                v-model="newPassword"
                :label="$t('newpwd') | capitalize"
                :error-messages="newPasswordErrors"
                counter
                :rules="[rules.required, rules.lowercase, rules.uppercase,
                rules.special, rules.number,  rules.minSize]"
                :type="showPwd ? 'text' : 'password'"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPwd = !showPwd"
                autocomplete="new-password"
                required
              ></v-text-field>

              <p class="mt-2"> Règles de sécurité des mots de passe :</p>
              <ul>
                <li>Au moins 10 caractères</li>
                <li>Au moins une minuscule</li>
                <li>Au moins une majuscule</li>
                <li>Au moins un chiffre</li>
                <li>Au moins un symbole</li>
              </ul>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              :disabled="!valid"
              :loading="loading"
              @click="changePwd"
            >
              {{ $t("valid") }}
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapState } from "vuex";

export default {
  data: function () {
    return {
      oldPassword: "",
      newPassword: "",
      oldPasswordErrors: [],
      newPasswordErrors: [],
      showPwd: false,
      valid: true,
      loading: false,
      rules: {
        required: (value) => !!value || 'Requis',
        minSize: (v) => v.length >= 10 || 'Au moins 10 characters',
        lowercase: (v) => /.*[a-z].*/.test(v) || 'Au moins ume minuscule',
        uppercase: (v) => /.*[A-Z].*/.test(v) || 'Au moins une majuscule',
        // eslint-disable-next-line no-useless-escape
        special: (v) => /.*[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\'':;|_~`].*/.test(v)
          || 'Au moins un symbole',
        number: (v) => /.*[0-9].*/.test(v) || 'Au moins un chiffre',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  methods: {
    changePwd() {
      this.loading = true;
      this.oldPasswordErrors = [];
      this.newPasswordErrors = [];

      Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, this.oldPassword, this.newPassword))
        .then(() => {
          this.loading = false;
          this.$router.push("/");
          this.$store.commit(
            "layout/SET_SNACK_DATAS",
            {
              text: "Votre mot de passe a bien été modifié",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((err) => {
          this.loading = false;

          let friendlyMessage;
          switch (err.code) {
            case "NotAuthorizedException":
              friendlyMessage = "Ce mot de passe ne correspond pas au mot de passe actuel.";
              this.oldPasswordErrors.push(friendlyMessage);
              break;
            case "InvalidParameterException":
              friendlyMessage = "Ce nouveau mot de passe ne respecte pas les règles de sécurité.";
              this.newPasswordErrors.push(friendlyMessage);
              break;
            case 'LimitExceededException':
              friendlyMessage = "Veuillez essayer plus tard. Nombre d'essais épuisés.";
              this.newPasswordErrors.push(friendlyMessage);
              break;
            default:
              friendlyMessage = `Echec: ${err.message}`;
              this.newPasswordErrors.push(
                "Ce nouveau mot de passe ne respecte pas les règles de sécurité.",
              );
          }
          console.log(friendlyMessage, err); //TODO Snackbars ?
        });
    },
  },
};
</script>
